/* global bootstrap */
'use strict';

// Native JS scripts for:
// * Enabling Bootstrap tooltips
// * Basic copy text functionality
// * Disabling submit button on form submission

// Enable popper.js tooltips
document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(function(elem) {
  new bootstrap.Tooltip(elem);
});

// HTML escaped unicode symbols for clipboard and checkmark
const CLIPBOARD = '&#128203;';
const CHECKMARK = '&#9989;';

function copyText(evt) {
  const textToCopy = evt.target.dataset.text || evt.target.innerHTML;
  navigator.clipboard.writeText(textToCopy).then(function() {
    evt.target.innerHTML = CHECKMARK; // Replace the clipboard with a checkmark
    setTimeout(function() {
      // After a short delay return the checkmark to a clipboard symbol
      evt.target.innerHTML = CLIPBOARD;
    }, 2500);
  });
}

// Enable copy to clipboard behavior for any copy-text elements
document.querySelectorAll('.copy-text').forEach(function(elem) {
  elem.addEventListener('click', copyText, false);
});

// Enable any disable on submit form buttons
document.querySelectorAll('.disable-on-submit').forEach(function(form) {
  form.addEventListener('submit', function() {
    form.querySelectorAll('[type=submit]').forEach(function(btn) {
      btn.disabled = true;
      const newValue = btn.dataset.submitting || 'Submitting...';
      if (btn.tagName === 'BUTTON') {
        btn.innerText = newValue;
      } else {
        btn.value = newValue;
      }
    });
  });
});
